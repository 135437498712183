.shop-chat {
  position: fixed !important;
  width: 500px;
  height: 500px;
  bottom: 100px;
  z-index: 999999;
  overflow-y: hidden;
}
.chat-q {
  background-color: #f0f8ff;
  border: solid 2px #4a90e2;
  text-align: center;
  cursor: pointer;
  border-radius: 15px;
  padding: 10px 15px;
  display: inline-block;
  color: #4a90e2;
  font-size: 11px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  max-width: 150px;
}

.chat-q:hover {
  background-color: #4a90e2;
  color: white;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 600px) {
  .chat-q {
    width: 40px;
    height: 40px;
    font-size: 9px;
    padding: 8px;
  }
}

@media (max-width: 400px) {
  .chat-q {
    width: 35px;
    height: 35px;
    font-size: 8px;
    padding: 3px;
  }
}

.questions-chat {
  gap: 4px;
  justify-content: center;
}
.card-body {
  overflow-y: scroll;
  position: relative;
}
.chat-hide {
  transition: right 1s;
  right: -550px;
}

.chat-show {
  transition: right 1s;
  right: 100px;
}

@media (max-width: 768px) {
  .shop-chat {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .chat-hide {
    transition: right 1s;
    right: calc(-100%);
  }

  .chat-show {
    transition: right 1s;
    right: 0px;
  }
}

.show-q {
  visibility: visible;
  transition: height 1s;
  height: auto;
}
.hide-q {
  visibility: hidden;
  transition: height 1s;

  height: 0;
  overflow: hidden;
}

.forms-chat {
  background-color: white;
  position: sticky;
  bottom: 10px;
}

.chatboxnew::placeholder {
  font-size: 16px !important;
}

@media (max-width: 576px) {
  .chatboxnew::placeholder {
    font-size: 3px;
  }
}
