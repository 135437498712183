/* User.css */

.user-form {
  max-width: 600px;
  margin: auto;
  padding: 2rem;
  background: linear-gradient(135deg, #ffffff, #f0f4f8);
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  border: 1px solid #d1d5db;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease;
  margin-top: 20px;
  margin-bottom: 30px;
}

.user-form:hover {
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  border-color: #b0bec5;
}

.user-form .form-group {
  margin-bottom: 1.5rem;
}

.user-form .form-label {
  font-size: 1.1rem;
  font-weight: 400;
  color: #333;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: -1px;
  margin-top: 10px;
}

.user-form .form-label i {
  margin-right: 0.5rem;
  color: #e63928;
}

.user-form .form-control {
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.user-form .form-control:focus {
  border-color: #00bfae;
  box-shadow: 0 0 0 0.2rem rgba(0, 191, 174, 0.25);
  outline: none;
}

.user-form .form-control.is-invalid {
  border-color: #e57373;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.user-form .form-control-feedback {
  color: #e57373;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.user-button {
  background: #e63928 !important;
  border: none;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-weight: 600;
}

.user-button:hover {
  background: #d62839 !important;
  transform: translateY(-3px);
}

.user-button:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 97, 0.5);
}
