/* .new-pageheader {
  position: relative;
  height: 300px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.new-page-header__bg {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f0f0f0;
  position: absolute;
  top: 0;
  left: 0;
} */

/* ImageBox.css */

/* Mobile Styles */
@media (max-width: 767px) {
  .image-box {
    width: 100%;
    height: 300px; /* Adjust height for mobile */
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-box img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the whole image is visible */
  }
}

/* Desktop Styles */
@media (min-width: 768px) {
  .image-box {
    width: 100%; /* Width for desktop */
    height: 350px; /* Height for desktop */
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-box img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the whole image is visible */
  }
}
