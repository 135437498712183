.pincode-form {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  margin-bottom: 200px;
}

.pincode-input {
  font-size: 0.9rem !important;
  padding: 10px !important;
  border-radius: 10px !important;
  border: 1px solid #ced4da !important;
}

.pincode-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  border-color: #80bdff !important;
}

.pincode-button {
  margin-left: 5px !important;
  padding: 3px 10px !important;
  font-size: 0.9rem !important;
  background-color: #e0f7fa !important;
  color: black !important;
  border-radius: 20px !important;
}
