.accordian-warning-button {
  margin-bottom: -26px;
  margin-left: 5px;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.popup input,
.popup textarea {
  display: block;
  margin-bottom: 10px;
}
.popup button {
  margin-right: 10px;
}
