.faq-title {
  font-weight: bold;
  color: #e63928;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.faq-accordion {
  /* border-radius: 0.75rem; */
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.faq-item {
  border: none;
  background-color: #ffffff;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
}

.faq-header {
  background-color: #ede9fe;
  color: #5a2d82;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 0.9rem;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  cursor: pointer;
  background-color: white !important;
}

.faq-header .accordion-button {
  color: #5a2d82;
  font-weight: bold;
  background-color: #ede9fe;
  border: none;
  box-shadow: none;
  border-radius: 0.5rem;
  padding: 1rem;
  transition: background-color 0.3s ease-in-out;
}

.faq-header .accordion-button:not(.collapsed) {
  background-color: #d8b4fe;
  color: #fff;
  border-radius: 0.5rem;
}

.faq-body {
  background-color: #f9fafb;
  color: #333;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
  font-size: 0.95rem;
  border-radius: 0.5rem;
}

.faq-header:hover,
.faq-header:focus {
  background-color: #e0d8fe;
}

.faq-header .accordion-button:hover,
.faq-header .accordion-button:focus {
  background-color: #e0d8fe;
}
