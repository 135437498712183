.eligibility-container {
  max-width: 1000px !important;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  top: 20px;
}

h2 {
  font-family: "Poppins", sans-serif;
  color: #343a40;
  font-weight: 600;
  margin-bottom: 20px;
}

.form-label {
  font-family: "Poppins", sans-serif;
  color: #495057;
  font-weight: 500;
}

.form-control,
.form-select {
  border-radius: 20px !important;
  padding: 10px;
  border: 1px solid #ced4da;
  transition: border-color 0.3s;
}

.form-control:focus,
.form-select:focus {
  border-color: #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.btn {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.border {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.border .form-control {
  margin-bottom: 10px;
}

.border .form-control:last-child {
  margin-bottom: 0;
}

.bg-light {
  background-color: #e9ecef !important;
  border-radius: 10px;
}

.bg-light h4 {
  font-family: "Poppins", sans-serif;
  color: #495057;
  font-weight: 600;
  margin-bottom: 10px;
}

.bg-light p {
  font-family: "Poppins", sans-serif;
  color: #343a40;
  font-weight: 400;
  margin-bottom: 5px;
}

.calc-add-button {
  padding: 3px 5px;
  font-size: 0.9rem;
  background-color: #e0f7fa !important;
  color: black !important;
  border-radius: 20px !important;
  border-color: #218838;
}
.calc-calculate-button {
  padding: 3px 5px;
  font-size: 0.9rem;
  background-color: #e0f7fa !important;
  color: black !important;
  border-radius: 20px !important;
  border-color: #218838;
}
.eligi-calc {
  background-color: #c3f0ff !important;
}

/* popup model */
/* Modal Styling */
.modal.show.d-block {
  display: block;
  background-color: rgba(0, 0, 0, 0.5); /* Background overlay */
}

.modal-dialog {
  max-width: 400px;
  margin: 1.75rem auto;
}

.modal-content {
  border-radius: 20px;
  background: linear-gradient(135deg, #f9f9f9 30%, #e0e0e0);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.modal-header {
  background-color: transparent;
  border-bottom: none;
  padding-bottom: 0;
  position: relative;
  z-index: 2;
}

.modal-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #4b2e83; /* Purple color for title */
  text-align: center;
  margin: 0 auto 15px;
}

.btn-close {
  background-color: #4b2e83; /* Custom close button color */
  border: none;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 3;
}

.modal-body {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: left;
  position: relative;
  z-index: 2;
}

.modal-body h4 {
  color: #4b2e83;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}

.modal-body p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.modal-footer {
  border-top: none;
  text-align: center;
  margin-top: 15px;
}

.btn-secondary {
  background-color: #4b2e83;
  color: white;
  border-radius: 50px;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-secondary:hover {
  background-color: #372566;
}

/* Card Design Extras */
.modal-content::before {
  content: "";
  position: absolute;
  top: -60px;
  left: -60px;
  width: 200px;
  height: 200px;
  background-color: #4b2e83;
  border-radius: 50%;
  z-index: 1;
}

.modal-content::after {
  content: "";
  position: absolute;
  bottom: -60px;
  right: -60px;
  width: 200px;
  height: 200px;
  background-color: #4b2e83;
  border-radius: 50%;
  z-index: 1;
}

/* loanamount */

.prize-box-container {
  text-align: center;
  padding: 20px;
}

.prize-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #5a2d82;
  margin-bottom: 10px;
}

.prize-box {
  background-color: #ffd700; /* Gold-like color for prize */
  padding: 20px;
  border-radius: 15px;
  display: inline-block;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: bounce 1.5s infinite ease-in-out; /* Bouncing animation */
}

.prize-amount {
  font-size: 2rem;
  font-weight: bold;
  color: #4b2e83;
}

.confetti {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("https://link-to-your-confetti-image.png"); /* Optional confetti image */
  background-size: contain;
  background-repeat: no-repeat;
  animation: confetti-fall 2s infinite ease-in-out; /* Confetti animation */
}

/* Bouncing animation */
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Confetti animation */
@keyframes confetti-fall {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
