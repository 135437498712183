.swiper-pagination-bullet {
  background: white !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: red !important;
}
