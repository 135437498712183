/* RupeeSpinner.css */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rupee-container {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px; /* 3D effect */
}

.note {
  position: absolute;
  width: 100px;
  height: 50px;
  background-color: gold;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  border-radius: 10px;
  animation: spin 1s infinite ease-in-out;
}

.note1 {
  animation-delay: 0s;
  transform-origin: 50% 100%;
  background-color: #babdc0;
}

.note2 {
  animation-delay: 0.2s;
  transform-origin: 50% 100%;
  background-color: #babdc0;
}

.note3 {
  animation-delay: 0.4s;
  transform-origin: 50% 100%;
  background-color: #babdc0;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg) translateZ(0px);
  }
  50% {
    transform: rotateY(180deg) translateZ(50px);
  }
  100% {
    transform: rotateY(360deg) translateZ(0px);
  }
}
