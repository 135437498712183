/* ----------------------additional column css------------------------- */
.additional-column-leads-hours-section {
  margin-top: 30px;
}

.additional-column-card {
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  padding: 20px;
  overflow: hidden;
  position: relative;
  border: 2px solid transparent;
}

.additional-column-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  z-index: 0;
  transform: rotate(-1deg);
}

.additional-column-card:hover {
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px);
}

.additional-column-card.card-left {
  border-left: 8px solid #e74c3c;
}

.additional-column-card.card-right {
  border-right: 8px solid #e74c3c;
}

.additional-column-leads-hours-header {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.additional-column-leads-hours-header h2 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #2c3e50;
  margin: 0;
  text-align: center;
  position: relative;
  z-index: 1;
}

.additional-column-leads-hours-card {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.additional-column-leads-hours-card p {
  font-size: 1.1rem;
  color: #34495e;
  margin: 0;
}

@media (max-width: 767.98px) {
  .additional-column-card {
    padding: 15px;
  }

  .additional-column-leads-hours-header h2 {
    font-size: 1.5rem;
  }

  .additional-column-leads-hours-card p {
    font-size: 1rem;
  }
}

/* -----------------------shedule css --------------------------------- */
.shedule-section {
  background-color: #f8f9fa;
  padding: 40px 20px;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.shedule-header h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #343a40;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.shedule-card {
  background: linear-gradient(135deg, #42a5f5 0%, #478ed1 100%);
  color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.shedule-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px;
}
/* ---------------------------------accordian css---------------------------- */
.accordian-accordion {
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 20px;
}

.accordian-accordion-header h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.accordian-accordion-button {
  background-color: #e63928;
  color: #fff;
  font-size: 1.2rem;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  transition: background-color 0.3s ease;
}

.accordian-accordion-button:not(.collapsed) {
  background-color: #1e88e5;
}

.accordian-accordion-button:hover {
  background-color: #1e88e5;
}

.accordian-accordion-body {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.accordian-accordion-body ul {
  padding-left: 20px;
}

.accordian-accordion-body ul li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.accordian-accordion-item {
  margin-bottom: 15px;
}

@media (max-width: 767.98px) {
  .accordian-accordion-button {
    font-size: 1rem;
    padding: 10px;
  }

  .accordian-accordion-body {
    padding: 15px;
  }
}

/* -----------------------------price table css---------------------------- */
.pricetable-card {
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #ffffff 0%, #f1f1f1 100%);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.pricetable-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.pricetable-card-header {
  background: linear-gradient(135deg, #e63928 0%, #e63928 100%);
  color: #ffffff;
  padding: 20px;
  border-bottom: 2px solid #1e88e5;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.pricetable-card-header h5 {
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0;
}

.pricetable-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.pricetable-table tbody tr.pricetable-row {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

.pricetable-table tbody tr.pricetable-row:hover {
  background-color: #e3f2fd;
}

.pricetable-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #333;
}

.pricetable-price {
  font-size: 1.125rem;
  font-weight: 400;
  color: #00796b;
}

.pricetable-row td {
  padding: 15px;
}

@media (max-width: 767.98px) {
  .pricetable-card-header h5 {
    font-size: 1.4rem;
  }

  .pricetable-title,
  .pricetable-price {
    font-size: 1rem;
  }

  .pricetable-row td {
    padding: 10px;
  }
}

/* --------------------------leads count------------------------- */
.leadscount-header {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 40px;
  padding: 10px 0;
}

.leadscount-column {
  margin-bottom: 20px;
}

.leadscount-card {
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  background: linear-gradient(145deg, #ffffff, #f9f9f9);
}

.leadscount-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.leadscount-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.leadscount-amount {
  font-size: 2rem;
  font-weight: 700;
  color: #e63928;
}

.leadscount-icon {
  font-size: 2.5rem;
  color: #00796b;
  margin-right: 10px;
}

.leadscount-logo img {
  height: 50px;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2));
}

@media (max-width: 767.98px) {
  .leadscount-header {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .leadscount-amount {
    font-size: 1.5rem;
  }

  .leadscount-icon {
    font-size: 2rem;
  }
}
/* ---------------------------header-------------------------- */
.leadscount-header {
  font-size: 2rem;
  font-weight: 700;
  color: #2c3e50;
  text-align: center;
  margin: 10px 0;
  position: relative;

  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

  padding-bottom: 10px;
}

.leadscount-header::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background: #3498db;
  margin: 10px auto 0;
  border-radius: 2px;
}

/* username */
.username-container {
  font-family: "Arial", sans-serif;
  /* background: #e63928; */
  color: #e63928;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-size: 1.2em;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); */
  margin-top: -24px;
}

.username-container::before {
  content: "👋 ";
  font-size: 1.4em;
}

.username-container span {
  font-weight: bold;
}
