.emicalc-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.emicalc-form-control {
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.emicalc-input-group {
  border-radius: 8px;
}

.emicalc-input-group-text {
  border-radius: 8px 0 0 8px;
}

.emicalc-icon {
  color: #f3471c;
  /* animation: emicalc-rotate 5s linear infinite; */
  font-size: 1.5rem;
}

@keyframes emicalc-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.emicalc-fromlabel {
  color: #333;
  font-weight: bold;
}

.emicalc-progressbar {
  background-color: #e0e0e0;
  border-radius: 8px;
}

.emicalc-progressbar .progress-bar {
  background-color: #e63928;
  border-radius: 8px;
}

.emicalc-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9rem;
}

.emicalc-table th,
.emicalc-table td {
  border: 2px solid #e63928;
  padding: 12px;
  text-align: center;
}

.emicalc-table th {
  color: #333;
  font-weight: bold;
}

.emicalc-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.emicalc-table tbody tr:nth-child(odd) {
  background-color: #fff;
}

.emicalc-table tbody tr:hover {
  background-color: #f1f1f1;
}

@media (max-width: 768px) {
  .emicalc-table {
    font-size: 0.8rem;
  }
}

/* -----------elgtable css------------ */
/* Custom table styles */
.elgtable-table {
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.elgtable-thead {
  background-color: #e63928; /* Background color for the header */
  color: #ffffff !important; /* Text color for the header */
}

.elgtable-thead th {
  padding: 12px;
  font-weight: bold;
  text-align: center;
}

.elgtable-table td {
  padding: 12px;
  text-align: center;
}

.elgtable-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.elgtable-table tr:hover {
  background-color: #e9ecef;
}
