.bottom-footer-company {
  background-color: #0b0b93; /* Adjust background color as needed */
  padding: 20px 0;
}

.bottom-footer__text-company {
  font-size: 16px; /* Default font size for larger screens */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .bottom-footer__text-company {
    font-size: 14px; /* Smaller font size for mobile screens */
  }
}

.copyright-foot {
  padding-top: 50px;
  padding-bottom: 50px;
}

.main-foot {
  /* padding-bottom: 50px; */
}
