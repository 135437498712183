.employment-container {
  margin-top: 20px;
}

.employment-option {
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.employment-option.selected {
  background-color: #e0f7fa;
  border-color: #00bcd4;
}

.employment-option:hover {
  background-color: #e0f7fa;
  border-color: #00bcd4;
}

.employment-icon {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}

.employment-button {
  padding: 10px 40px;
  font-size: 0.9rem;
  background-color: #e0f7fa !important;
  border-radius: 20px !important;
  border-color: #00bcd4;
}

.new-title {
  background-color: #e0f7fa;
  border-color: #00bcd4;
  border-radius: 25px;
}
.new-eligiblity-label {
  font-size: 20px;
}
