.loan-calculator-container {
  max-width: 80%;
  margin: auto;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  background: linear-gradient(135deg, #eaf2f8, #f9f9f9);
  margin-top: 30px !important;
  margin-bottom: 40px;
}

.loan-calculator-title {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 700;
  background: #e63928;
  background-clip: text;
  color: transparent;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.loan-calculator-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.loan-calculator-table thead th {
  background: #e63928;
  color: white;
  padding: 12px;
  font-size: 1.1rem;
  text-align: left;
  border-radius: 8px 8px 0 0;
}

.loan-calculator-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.loan-calculator-table td {
  background: #ffffff;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  vertical-align: top;
}

.loan-calculator-table tbody tr:nth-child(even) td {
  background: #f5f5f5;
}

.loan-calculator-input-group {
  margin-bottom: 15px;
}

.loan-calculator-input-group input {
  margin-bottom: 10px;
  background-color: #ffffff;
}

.loan-calculator-button {
  background: #e63928 !important;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px !important;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.3s ease;
  font-family: "Poppins", sans-serif;
}

.loan-calculator-button:hover {
  background: linear-gradient(135deg, #ff8a65, #ff6f61);
}

.loan-calculator-button:active {
  transform: scale(0.98);
}

.loan-calculator-calculate-button {
  background: linear-gradient(135deg, #00bcd4, #03a9f4);
}

.loan-calculator-calculate-button:hover {
  background: linear-gradient(135deg, #03a9f4, #00bcd4);
}

.loan-calculator-results {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.loan-calculator-results div {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #333;
}

.loan-calculator-results strong {
  color: #e63928;
}

/* @media (max-width: 768px) {
  .loan-calculator-table {
    display: block;
    border: none;
  }

  .loan-calculator-table thead {
    display: none;
  }

  .loan-calculator-table tbody tr {
    display: block;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  }

  .loan-calculator-table td {
    display: block;
    padding: 15px;
    border: none;
    border-bottom: 1px solid #ddd;
    position: relative;
    text-align: left;
    padding: 0.5rem 2.5rem !important;
  }

  .loan-calculator-table td:before {
    content: attr(data-label);
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
    color: #ff6f61;
  }

  .loan-calculator-title {
    font-size: 1.5rem;
  }

  .loan-calculator-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .loan-calculator-results {
    padding: 10px;
  }
} */

.popup-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.6) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1000 !important;
}

.popup-content {
  background: white !important;
  border-radius: 8px !important;
  padding: 20px !important;
  max-width: 400px !important;
  width: 100% !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  animation: fadeIn 0.3s ease-in-out !important;
  position: relative !important;
}

.popup-title {
  margin: 0 !important;
  font-size: 1.5rem !important;
  color: #333 !important;
}

.popup-body {
  margin-top: 15px !important;
}

.popup-item {
  margin: 10px 0 !important;
  font-size: 1rem !important;
  color: #555 !important;
}

.popup-close {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  border: none !important;
  background: none !important;
  font-size: 1.5rem !important;
  cursor: pointer !important;
  color: #888 !important;
}

.popup-close:hover {
  color: #555 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0 !important;
    transform: scale(0.9) !important;
  }
  to {
    opacity: 1 !important;
    transform: scale(1) !important;
  }
}

/* Overlay styling */
.popup-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.7) !important; /* Slightly darker background */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1000 !important;
  animation: fadeInOverlay 0.4s ease-in-out !important;
}

/* Popup content styling */
.popup-content {
  background: #fff !important;
  border-radius: 12px !important;
  padding: 20px !important;
  max-width: 500px !important;
  width: 90% !important;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4) !important;
  animation: scaleUp 0.5s ease-out !important;
  position: relative !important;
  overflow: hidden !important;
  text-align: center !important;
}

/* Header styling */
.popup-header {
  background: linear-gradient(
    135deg,
    #ff6f61,
    #ffcc00
  ) !important; /* Gradient header */
  color: white !important;
  border-radius: 12px !important;
  padding: 15px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

/* Title and subtitle styling */
.popup-title {
  margin: 0 !important;
  font-size: 2rem !important;
  font-weight: bold !important;
}

.popup-subtitle {
  margin: 10px 0 0 !important;
  font-size: 1.25rem !important;
  font-style: italic !important;
}

/* Body styling */
.popup-body {
  margin-top: 20px !important;
}

/* Item styling */
.popup-item {
  margin: 15px 0 !important;
  font-size: 1.2rem !important; /* Larger font size */
  color: #333 !important; /* Darker color */
  font-weight: bold !important; /* Bold text */
  transition: color 0.3s !important;
}

.popup-item:hover {
  color: #e74c3c !important; /* Change color on hover */
}

/* Footer styling */
.popup-footer {
  margin-top: 20px !important;
}

/* Button styling */
.popup-button {
  background: #ff6f61 !important;
  color: white !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 12px 25px !important;
  font-size: 1.1rem !important;
  cursor: pointer !important;
  transition: background 0.3s, transform 0.3s !important;
}

.popup-button:hover {
  background: #ff3b30 !important; /* Darker shade on hover */
  transform: scale(1.05) !important; /* Slight scale-up effect */
}

/* Close button styling */
.popup-close {
  position: absolute !important;
  top: 15px !important;
  right: 15px !important;
  border: none !important;
  background: #ffcc00 !important; /* Bright background */
  color: white !important; /* White text color */
  font-size: 2rem !important; /* Larger font size */
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important; /* Circular button */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: background 0.3s !important;
}

.popup-close:hover {
  background: #ff6f61 !important; /* Change background on hover */
}

/* Animation keyframes */
@keyframes fadeInOverlay {
  from {
    opacity: 0 !important;
  }
  to {
    opacity: 1 !important;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.8) !important;
    opacity: 0 !important;
  }
  to {
    transform: scale(1) !important;
    opacity: 1 !important;
  }
}

.obligations-input {
  background-color: white !important;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .loan-calculator-container {
    padding: 15px;
    font-size: 14px;
  }

  .loan-calculator-title {
    font-size: 18px;
    text-align: center;
  }

  .loan-calculator-table {
    width: 100%;
    border-collapse: collapse;
  }

  .loan-calculator-table th,
  .loan-calculator-table td {
    padding: 8px;
    text-align: left;
    display: block;
  }

  .loan-calculator-table thead {
    display: none;
  }

  .loan-calculator-table tr {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }

  .loan-calculator-table td::before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }

  .table-responsive {
    overflow-x: auto;
  }

  .loan-calculator-input-group {
    width: 100%;
    margin-bottom: 10px;
  }

  .loan-calculator-button {
    width: 100%;
    margin-top: 10px;
  }

  .popup-content {
    width: 90%;
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .popup-buttons {
    display: flex;
    flex-direction: column;
  }

  .popup-buttons .loan-calculator-button {
    margin-top: 10px;
    width: 100%;
  }
}
