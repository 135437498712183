.custom-table td,
.custom-table th {
  font-size: 0.8rem;
}

.custom-table .text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
